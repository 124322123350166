import * as React from 'react';
import { SVGProps } from 'react';

const SvgCrystalsSuccess = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={188}
      height={189}
      viewBox="0 0 188 189"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={94}
        cy={94.5}
        r={92}
        fill="#fff"
        stroke="#34D399"
        strokeWidth={4}
      />
      <path
        d="m150.136 41.492-.085-1.28a5.217 5.217 0 0 0-3.771-1.61h-27.582a5.217 5.217 0 0 0-3.771 1.61v1.28l7.125 11.682h22.011l6.073-11.682Z"
        fill="#6366F1"
      />
      <path
        d="M118.698 38.602a5.217 5.217 0 0 0-3.771 1.61v1.28l3.581 5.872 6.171-8.761h-5.981ZM132.489 38.602l-9.912 14.572.617.6h18.208l.999-.6-9.912-14.572Z"
        fill="#6366F1"
      />
      <path
        d="m114.927 40.213-9.902 10.356a3.768 3.768 0 0 0-1.046 2.605l.655.6h16.909l1.033-.6-7.649-12.961Z"
        fill="#6366F1"
      />
      <path
        d="m114.417 53.173 4.439-6.303-3.929-6.658-9.902 10.356a3.768 3.768 0 0 0-1.046 2.605l.655.6h10.095l-.312-.6ZM161 53.173c0-.97-.375-1.903-1.047-2.605l-9.902-10.356-7.65 12.961.761.6H160.5l.5-.6Z"
        fill="#6366F1"
      />
      <path
        d="M103.979 53.174c0 1.089.389 2.142 1.099 2.97L128.425 83.4a5.353 5.353 0 0 0 8.128 0l23.348-27.257a4.568 4.568 0 0 0 1.099-2.97h-57.021Z"
        fill="#6366F1"
      />
      <path
        d="M114.417 53.174h-10.438c0 1.089.389 2.142 1.099 2.97L128.426 83.4a5.342 5.342 0 0 0 2.596 1.662l-16.605-31.89Z"
        fill="#6366F1"
      />
      <path
        d="M132.489 85.269c.502 0 .995-.071 1.467-.206l8.445-31.89h-19.824l8.445 31.89c.472.135.965.206 1.467.206ZM101.715 66.314l-.748-2.192a9.434 9.434 0 0 0-7.332-1.052L45.532 75.913a9.396 9.396 0 0 0-5.822 4.564l.6 2.232 17.905 17.055 38.387-10.249 5.113-23.201Z"
        fill="#6366F1"
      />
      <path
        d="M45.532 75.914a9.397 9.397 0 0 0-5.822 4.564l.6 2.231 9 8.573 6.652-18.153-10.43 2.785ZM69.583 69.492 59.13 99.52l1.359.759 31.753-8.478 1.462-1.512-24.12-20.797Z"
        fill="#6366F1"
      />
      <path
        d="m39.71 80.477-12.413 22.671a6.798 6.798 0 0 0-.603 5.031l1.424.741 29.491-7.874 1.52-1.527-19.42-19.042Z"
        fill="#6366F1"
      />
      <path
        d="m44.9 103.319 4.785-13.06-9.976-9.781-12.412 22.671a6.798 6.798 0 0 0-.603 5.031l1.424.741 17.607-4.701-.826-.901ZM126.139 81.629a6.824 6.824 0 0 0-3.046-4.057l-22.126-13.45-7.263 26.167 1.607.692 30.238-8.074.59-1.278Z"
        fill="#6366F1"
      />
      <path
        d="M26.694 108.18a8.266 8.266 0 0 0 3.31 4.669l53.501 36.665a9.674 9.674 0 0 0 7.963 1.364 9.636 9.636 0 0 0 6.211-5.149l27.936-58.408a8.237 8.237 0 0 0 .524-5.692L26.694 108.18Z"
        fill="#6366F1"
      />
      <path
        d="m44.899 103.32-18.205 4.861a8.264 8.264 0 0 0 3.31 4.668l53.501 36.665a9.675 9.675 0 0 0 5.308 1.69L44.899 103.32ZM91.469 150.878a9.657 9.657 0 0 0 2.462-1.041l-.227-59.548L59.13 99.52l29.683 51.683a9.64 9.64 0 0 0 2.656-.325Z"
        fill="#6366F1"
      />
      <path
        d="m147.386 43.295-.073-1.1a4.482 4.482 0 0 0-3.241-1.383h-23.706c-1.225 0-2.397.5-3.241 1.383v1.1l6.124 10.04h18.918l5.219-10.04Z"
        fill="#C3DDFF"
      />
      <path
        d="M120.366 40.812c-1.225 0-2.397.5-3.241 1.383v1.1l3.078 5.047 5.303-7.53h-5.14Z"
        fill="#A4CCFF"
      />
      <path
        d="m132.219 40.812-8.52 12.523.531.516h15.649l.859-.516-8.519-12.523ZM117.124 42.195l-8.51 8.901a3.237 3.237 0 0 0-.9 2.24l.563.515h14.534l.888-.516-6.575-11.14Z"
        fill="#DBEAFF"
      />
      <path
        d="m116.686 53.335 3.816-5.418-3.378-5.722-8.51 8.901a3.237 3.237 0 0 0-.9 2.24l.563.515h8.678l-.269-.516Z"
        fill="#C3DDFF"
      />
      <path
        d="M156.723 53.335a3.24 3.24 0 0 0-.899-2.239l-8.511-8.9-6.575 11.14.654.515h14.902l.429-.516Z"
        fill="#DBEAFF"
      />
      <path
        d="M107.714 53.336c0 .936.335 1.841.945 2.553l20.067 23.427a4.6 4.6 0 0 0 3.493 1.605 4.6 4.6 0 0 0 3.493-1.605l20.067-23.427a3.927 3.927 0 0 0 .944-2.553h-49.009Z"
        fill="#A4CCFF"
      />
      <path
        d="M116.686 53.336h-8.972c0 .936.335 1.841.945 2.553l20.067 23.427a4.595 4.595 0 0 0 2.232 1.428l-14.272-27.408Z"
        fill="#8BB3EA"
      />
      <path
        d="M132.219 80.921a4.6 4.6 0 0 0 1.261-.177l7.258-27.408h-17.039l7.258 27.408c.407.116.831.177 1.262.177ZM99.28 70.477l-.676-1.98a8.525 8.525 0 0 0-6.626-.952L48.51 79.151a8.492 8.492 0 0 0-5.261 4.124l.542 2.017 16.18 15.412 34.689-9.262 4.62-20.965Z"
        fill="#C3DDFF"
      />
      <path
        d="M48.51 79.151a8.492 8.492 0 0 0-5.261 4.125l.542 2.016 8.134 7.747 6.01-16.404-9.425 2.516Z"
        fill="#A4CCFF"
      />
      <path
        d="m70.244 73.349-9.447 27.135 1.228.685 28.694-7.661 1.321-1.366L70.244 73.35Z"
        fill="#DBEAFF"
      />
      <path
        d="m43.248 83.275-11.216 20.488a6.142 6.142 0 0 0-.545 4.546l1.287.669 26.65-7.115 1.374-1.38-17.55-17.208Z"
        fill="#DBEAFF"
      />
      <path
        d="m47.938 103.917 4.325-11.803-9.014-8.839-11.217 20.488a6.142 6.142 0 0 0-.545 4.546l1.287.669 15.91-4.248-.746-.813Z"
        fill="#C3DDFF"
      />
      <path
        d="M121.351 84.316a6.165 6.165 0 0 0-2.753-3.666L98.604 68.496 92.04 92.142l1.453.625 27.324-7.296.534-1.155Z"
        fill="#DBEAFF"
      />
      <path
        d="M31.487 108.309a7.469 7.469 0 0 0 2.99 4.218l48.348 33.133a8.743 8.743 0 0 0 7.195 1.233 8.708 8.708 0 0 0 5.613-4.653l25.244-52.781a7.438 7.438 0 0 0 .473-5.144L31.487 108.31Z"
        fill="#A4CCFF"
      />
      <path
        d="m47.938 103.917-16.451 4.392a7.468 7.468 0 0 0 2.99 4.219l48.348 33.132a8.74 8.74 0 0 0 4.796 1.527l-39.683-43.27Z"
        fill="#8BB3EA"
      />
      <path
        d="M90.02 146.893a8.717 8.717 0 0 0 2.226-.941l-.206-53.81-31.243 8.341 26.824 46.704a8.75 8.75 0 0 0 2.4-.294Z"
        fill="#C3DDFF"
      />
    </svg>
  );
};
export default SvgCrystalsSuccess;
