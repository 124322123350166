import * as React from 'react';
import { SVGProps } from 'react';

const SvgApiIllustration = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={470}
      height={336}
      viewBox="0 0 470 336"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M445.038 70.154a7.63 7.63 0 0 0-2.342-2.313 7.813 7.813 0 0 0-3.121-1.129l-33.294-4.494-11.96-30.115a7.586 7.586 0 0 0-1.865-2.736 7.724 7.724 0 0 0-2.869-1.697 110.597 110.597 0 0 0-40.893-5.302 8.024 8.024 0 0 0-5.705 2.858l-21.611 25.937-33.507-4.255a8.02 8.02 0 0 0-6.133 1.794 110.83 110.83 0 0 0-26.257 31.84 7.715 7.715 0 0 0-.944 3.194 7.58 7.58 0 0 0 .493 3.27l11.724 30.24-21.31 26.005a7.817 7.817 0 0 0-1.516 2.955 7.643 7.643 0 0 0-.141 3.292 100.57 100.57 0 0 0 14.649 37.28c.601.93 1.4 1.719 2.343 2.313a7.804 7.804 0 0 0 3.12 1.129l33.294 4.494 11.964 30.124a7.583 7.583 0 0 0 1.865 2.736 7.705 7.705 0 0 0 2.868 1.697 110.585 110.585 0 0 0 40.894 5.302 8.007 8.007 0 0 0 5.705-2.858l21.621-25.993 33.506 4.255a7.9 7.9 0 0 0 3.922-.509 8.093 8.093 0 0 0 2.204-1.304 111.005 111.005 0 0 0 26.265-31.82 7.748 7.748 0 0 0 .944-3.194 7.597 7.597 0 0 0-.493-3.272l-11.724-30.239 21.31-26.005a7.824 7.824 0 0 0 1.516-2.955c.286-1.078.334-2.2.141-3.291a100.567 100.567 0 0 0-14.663-37.234Zm-79.195 94.17a39.906 39.906 0 0 1-22.716 1.965c-7.569-1.571-14.464-5.326-19.811-10.791a37.818 37.818 0 0 1-10.229-19.91c-1.323-7.517-.348-15.284 2.8-22.319 3.148-7.035 8.329-13.022 14.886-17.205a40.096 40.096 0 0 1 21.955-6.29c7.757.08 15.281 2.427 21.623 6.742a37.978 37.978 0 0 1 14.003 17.504c3.739 9.517 3.468 20.163-.754 29.596-4.221 9.434-12.048 16.883-21.757 20.708Z"
        fill="url(#a)"
      />
      <ellipse cx={240.203} cy={310.684} rx={210} ry={10.611} fill="url(#b)" />
      <g clipPath="url(#c)">
        <rect
          x={170.001}
          y={42}
          width={100}
          height={71.488}
          rx={6.728}
          fill="#DBDBDB"
        />
        <path
          d="M170.001 48.728A6.728 6.728 0 0 1 176.729 42h86.543a6.729 6.729 0 0 1 6.729 6.728V58.82h-100V48.728Z"
          fill="#E9E9E9"
        />
        <rect
          opacity={0.2}
          x={256.555}
          y={47.046}
          width={6.723}
          height={6.728}
          rx={3.361}
          fill="#252525"
        />
        <rect
          opacity={0.2}
          x={243.95}
          y={47.046}
          width={6.723}
          height={6.728}
          rx={3.361}
          fill="#252525"
        />
        <rect
          opacity={0.2}
          x={231.345}
          y={47.046}
          width={6.723}
          height={6.728}
          rx={3.361}
          fill="#252525"
        />
        <path
          opacity={0.2}
          fill="#818CF8"
          d="M176.932 66h15.126v3.364h-15.126z"
        />
        <path
          fill="#ECF4F5"
          fillOpacity={0.16}
          d="M176.723 72.277h15.126v3.364h-15.126z"
        />
        <path fill="#818CF8" d="M176.723 82.37h15.126v3.364h-15.126z" />
        <path
          opacity={0.2}
          fill="#818CF8"
          d="M249.201 66h15.126v3.364h-15.126z"
        />
        <path
          fill="#ECF4F5"
          fillOpacity={0.16}
          d="M248.992 95.826h15.126v3.364h-15.126z"
        />
        <path fill="#818CF8" d="M196.891 65.549h49.58v3.364h-49.58z" />
        <path fill="#E9E9E9" d="M196.891 95.826h49.58v3.364h-49.58z" />
        <path
          opacity={0.2}
          fill="#818CF8"
          d="M196.891 72.277h26.05v3.364h-26.05z"
        />
        <path fill="#E9E9E9" d="M196.891 82.37h31.933v3.364h-31.933z" />
        <path
          fill="#ECF4F5"
          fillOpacity={0.16}
          d="M196.891 89.098h20.168v3.364h-20.168z"
        />
        <path fill="#818CF8" d="M196.891 102.554h20.168v3.364h-20.168z" />
        <path
          fill="#ECF4F5"
          fillOpacity={0.16}
          d="M226.304 72.277h26.05v3.364h-26.05z"
        />
        <path
          fill="#818CF8"
          d="M230.505 82.37h32.773v3.364h-32.773zM220.421 89.098h20.168v3.364h-20.168z"
        />
        <path
          fill="#ECF4F5"
          fillOpacity={0.16}
          d="M220.421 102.554h20.168v3.364h-20.168z"
        />
        <path fill="#E9E9E9" d="M243.95 89.098h20.168v3.364H243.95z" />
      </g>
      <rect
        x={169.227}
        y={41.227}
        width={101.546}
        height={73.034}
        rx={7.505}
        stroke="#E9E9E9"
        strokeWidth={1.546}
      />
      <g filter="url(#d)">
        <path fill="url(#e)" d="M171 76.052h99.032v7.074H171z" />
      </g>
      <path
        d="M133.409 197.921a5.038 5.038 0 0 0-1.548-1.528 5.168 5.168 0 0 0-2.063-.746l-22.001-2.972-7.907-19.896a5.01 5.01 0 0 0-1.232-1.808 5.114 5.114 0 0 0-1.896-1.122 73.096 73.096 0 0 0-27.023-3.506 5.29 5.29 0 0 0-3.77 1.888l-14.278 17.132-22.142-2.814a5.295 5.295 0 0 0-4.053 1.185 73.186 73.186 0 0 0-17.348 21.031 5.112 5.112 0 0 0-.624 2.11 5.01 5.01 0 0 0 .327 2.161l7.75 19.978-14.08 17.178a5.165 5.165 0 0 0-1.001 1.952 5.044 5.044 0 0 0-.093 2.174 66.447 66.447 0 0 0 9.684 24.63 5.044 5.044 0 0 0 1.548 1.529 5.165 5.165 0 0 0 2.063.745l22.001 2.973 7.91 19.902a5.013 5.013 0 0 0 1.232 1.808c.54.505 1.187.888 1.895 1.121a73.118 73.118 0 0 0 27.024 3.507 5.292 5.292 0 0 0 3.77-1.888l14.284-17.17 22.142 2.815a5.221 5.221 0 0 0 2.592-.336 5.35 5.35 0 0 0 1.456-.861 73.317 73.317 0 0 0 17.353-21.019c.36-.653.572-1.373.624-2.11a5.002 5.002 0 0 0-.327-2.161l-7.75-19.978 14.08-17.178a5.17 5.17 0 0 0 1.001-1.952 5.037 5.037 0 0 0 .093-2.174 66.454 66.454 0 0 0-9.693-24.6Zm-52.324 62.204a26.366 26.366 0 0 1-15.01 1.296 25.57 25.57 0 0 1-13.093-7.131 24.985 24.985 0 0 1-6.762-13.154 25.33 25.33 0 0 1 1.848-14.744 26.186 26.186 0 0 1 9.836-11.365 26.495 26.495 0 0 1 14.507-4.153 25.865 25.865 0 0 1 14.29 4.456 25.1 25.1 0 0 1 9.255 11.565c2.471 6.287 2.293 13.32-.495 19.552-2.79 6.232-7.96 11.152-14.376 13.678Z"
        fill="url(#f)"
      />
      <rect
        x={100.944}
        y={81.346}
        width={271.105}
        height={180.888}
        rx={8.66}
        fill="#D5D5D5"
        stroke="#F9FAFB"
        strokeWidth={1.924}
      />
      <rect
        x={111.518}
        y={91.93}
        width={249.957}
        height={159.72}
        rx={1.924}
        fill="#E4E4E4"
      />
      <path
        d="M111.518 93.854c0-1.063.862-1.924 1.925-1.924H359.55c1.063 0 1.925.861 1.925 1.924v21.168H111.518V93.854Z"
        fill="#E9E9E9"
      />
      <path
        d="M198.042 263.196h75.948l3.845 37.525h-83.639l3.846-37.525Z"
        fill="#BEBEBE"
      />
      <path
        d="M198.041 263.196h75.949l.961 11.546H197.08l.961-11.546Z"
        fill="#B0AFAF"
      />
      <path
        d="M177.853 293.986a7.698 7.698 0 0 1 7.698-7.698h101.892a7.698 7.698 0 0 1 7.697 7.698v6.735H177.853v-6.735Z"
        fill="#D4D4D4"
      />
      <rect
        opacity={0.5}
        x={342.384}
        y={99.835}
        width={7.691}
        height={7.697}
        rx={3.845}
        fill="#fff"
      />
      <rect
        opacity={0.5}
        x={327.384}
        y={99.835}
        width={7.691}
        height={7.697}
        rx={3.845}
        fill="#fff"
      />
      <rect
        opacity={0.5}
        x={313.384}
        y={99.835}
        width={7.691}
        height={7.697}
        rx={3.845}
        fill="#fff"
      />
      <path
        d="M284.445 170.981a3.525 3.525 0 0 0-1.71-2.377l-13.113-7.482-.052-14.797a3.528 3.528 0 0 0-1.244-2.676 49.177 49.177 0 0 0-16.141-9.097 3.518 3.518 0 0 0-2.84.259l-13.226 7.403-13.24-7.416a3.5 3.5 0 0 0-2.844-.264 49.163 49.163 0 0 0-16.123 9.133 3.525 3.525 0 0 0-1.244 2.671l-.066 14.811-13.113 7.482a3.524 3.524 0 0 0-1.71 2.377 46.917 46.917 0 0 0 0 18.291 3.525 3.525 0 0 0 1.71 2.377l13.113 7.482.052 14.802a3.535 3.535 0 0 0 1.244 2.676 49.195 49.195 0 0 0 16.141 9.097 3.503 3.503 0 0 0 2.84-.26l13.24-7.425 13.239 7.417a3.477 3.477 0 0 0 1.715.44c.384 0 .765-.063 1.129-.185a49.274 49.274 0 0 0 16.124-9.124 3.535 3.535 0 0 0 1.244-2.672l.066-14.81 13.112-7.482a3.513 3.513 0 0 0 1.71-2.377 46.926 46.926 0 0 0-.013-18.274Zm-48.326 26.756a17.573 17.573 0 0 1-9.769-2.967 17.607 17.607 0 0 1-7.476-18.073 17.608 17.608 0 0 1 4.812-9.014 17.569 17.569 0 0 1 27.052 2.667 17.623 17.623 0 0 1-2.187 22.23 17.568 17.568 0 0 1-12.432 5.157Z"
        fill="#818CF8"
      />
      <path
        d="M326.626 142.094a1.482 1.482 0 0 0-.718-1l-5.507-3.148-.022-6.224a1.475 1.475 0 0 0-.523-1.126 20.637 20.637 0 0 0-6.779-3.827 1.474 1.474 0 0 0-1.192.109l-5.555 3.114-5.561-3.119a1.46 1.46 0 0 0-1.194-.111 20.639 20.639 0 0 0-6.772 3.841 1.485 1.485 0 0 0-.523 1.124l-.027 6.231-5.507 3.147a1.488 1.488 0 0 0-.719 1 19.77 19.77 0 0 0 0 7.695 1.475 1.475 0 0 0 .719.999l5.507 3.148.022 6.226a1.477 1.477 0 0 0 .522 1.126 20.629 20.629 0 0 0 6.779 3.827 1.47 1.47 0 0 0 1.193-.109l5.561-3.123 5.56 3.119a1.5 1.5 0 0 0 1.195.108 20.682 20.682 0 0 0 6.771-3.838 1.487 1.487 0 0 0 .523-1.124l.028-6.231 5.507-3.147c.184-.105.343-.249.467-.422.124-.172.21-.369.251-.578.501-2.538.499-5.15-.006-7.687Zm-20.296 11.255a7.367 7.367 0 0 1-4.103-1.248 7.408 7.408 0 0 1-3.14-7.603 7.415 7.415 0 0 1 2.021-3.792 7.37 7.37 0 0 1 8.048-1.605 7.395 7.395 0 0 1 3.314 2.727 7.425 7.425 0 0 1-.919 9.352 7.373 7.373 0 0 1-5.221 2.169Z"
        fill="#FCFCFC"
      />
      <path
        opacity={0.2}
        d="M313.295 216.631a.958.958 0 0 0-.468-.65l-3.586-2.046-.014-4.046a.957.957 0 0 0-.34-.732 13.444 13.444 0 0 0-4.415-2.488.962.962 0 0 0-.776.071l-3.617 2.025-3.621-2.028a.969.969 0 0 0-.778-.072 13.435 13.435 0 0 0-4.41 2.497.969.969 0 0 0-.34.73l-.018 4.05-3.586 2.046a.95.95 0 0 0-.304.274.944.944 0 0 0-.163.376 12.793 12.793 0 0 0 0 5.001.944.944 0 0 0 .163.376.95.95 0 0 0 .304.274l3.586 2.046.015 4.047c0 .14.031.277.089.404a.972.972 0 0 0 .251.328 13.45 13.45 0 0 0 4.414 2.488.967.967 0 0 0 .777-.071l3.621-2.031 3.62 2.028a.953.953 0 0 0 .778.07 13.475 13.475 0 0 0 4.41-2.495.969.969 0 0 0 .34-.73l.018-4.05 3.586-2.046a.964.964 0 0 0 .468-.65 12.825 12.825 0 0 0-.004-4.996Zm-13.216 7.315a4.806 4.806 0 0 1-4.443-2.971 4.822 4.822 0 0 1 1.042-5.246 4.809 4.809 0 0 1 5.241-1.044 4.812 4.812 0 0 1 2.968 4.448 4.813 4.813 0 0 1-4.808 4.813Z"
        fill="#818CF8"
      />
      <path
        d="M430.146 92.753a1.06 1.06 0 0 0-.751-.473l-4.578-.618-1.645-4.14a1.037 1.037 0 0 0-.651-.61 15.204 15.204 0 0 0-5.623-.73 1.104 1.104 0 0 0-.784.393l-2.972 3.567-4.607-.585a1.099 1.099 0 0 0-.843.246 15.22 15.22 0 0 0-3.61 4.378 1.051 1.051 0 0 0-.062.89l1.612 4.157-2.931 3.576a1.075 1.075 0 0 0-.227.859 13.819 13.819 0 0 0 2.014 5.126 1.05 1.05 0 0 0 .751.473l4.578.618 1.645 4.142a1.051 1.051 0 0 0 .651.61c1.805.592 3.713.84 5.623.729a1.11 1.11 0 0 0 .784-.393l2.973-3.574 4.607.585c.183.023.368-.001.54-.07.11-.044.212-.104.303-.179a15.274 15.274 0 0 0 3.611-4.376 1.064 1.064 0 0 0 .062-.889l-1.612-4.158 2.93-3.575a1.049 1.049 0 0 0 .228-.859 13.84 13.84 0 0 0-2.016-5.12Zm-10.89 12.949a5.486 5.486 0 0 1-3.123.27 5.32 5.32 0 0 1-2.724-1.484 5.201 5.201 0 0 1-1.407-2.738 5.288 5.288 0 0 1 .385-3.069 5.457 5.457 0 0 1 2.047-2.365 5.515 5.515 0 0 1 3.019-.865 5.38 5.38 0 0 1 2.973.927 5.226 5.226 0 0 1 1.926 2.407 5.25 5.25 0 0 1-.104 4.069 5.47 5.47 0 0 1-2.992 2.848Z"
        fill="#BEBEBE"
      />
      <path
        d="M179.474 208.542a1.48 1.48 0 0 0-.719-1l-5.507-3.147-.022-6.225a1.48 1.48 0 0 0-.522-1.126 20.665 20.665 0 0 0-6.779-3.827 1.47 1.47 0 0 0-1.193.11l-5.555 3.114-5.561-3.12a1.48 1.48 0 0 0-1.194-.111 20.651 20.651 0 0 0-6.772 3.842 1.472 1.472 0 0 0-.522 1.124l-.028 6.23-5.507 3.147a1.489 1.489 0 0 0-.718 1 19.77 19.77 0 0 0 0 7.695 1.472 1.472 0 0 0 .718 1l5.507 3.147.022 6.227a1.491 1.491 0 0 0 .523 1.126 20.655 20.655 0 0 0 6.779 3.827 1.471 1.471 0 0 0 1.192-.11l5.561-3.123 5.561 3.12a1.5 1.5 0 0 0 1.194.107 20.687 20.687 0 0 0 6.772-3.838 1.475 1.475 0 0 0 .522-1.124l.028-6.23 5.507-3.148a1.486 1.486 0 0 0 .718-1c.501-2.538.5-5.15-.005-7.687Zm-20.297 11.255a7.369 7.369 0 0 1-4.103-1.248 7.422 7.422 0 0 1-1.119-11.394 7.374 7.374 0 0 1 11.362 1.122 7.417 7.417 0 0 1-.918 9.351 7.373 7.373 0 0 1-5.222 2.169Z"
        fill="#DDD"
      />
      <ellipse
        cx={236.497}
        cy={180.45}
        rx={23.073}
        ry={23.092}
        fill="#818CF8"
      />
      <path
        d="m208.932 190.071 10.16-21.706h4.465l10.16 21.706h-5.665l-1.878-4.403h-9.699l-1.878 4.403h-5.665Zm12.346-15.795-2.955 7.051h5.973l-2.956-7.051h-.062ZM235.032 190.071v-21.706h10.437c2.464 0 4.362.636 5.697 1.908 1.354 1.253 2.032 2.987 2.032 5.204 0 2.217-.678 3.961-2.032 5.234-1.335 1.252-3.233 1.878-5.697 1.878h-4.772v7.482h-5.665Zm5.665-11.823h3.787c2.217 0 3.325-.924 3.325-2.771s-1.108-2.771-3.325-2.771h-3.787v5.542ZM255.839 190.071v-21.706h5.665v21.706h-5.665Z"
        fill="#fff"
      />
      <rect
        x={29.318}
        y={71.179}
        width={116.172}
        height={83.553}
        rx={8.586}
        stroke="#F9FAFB"
        strokeWidth={1.768}
      />
      <rect
        x={30.203}
        y={72.063}
        width={114.403}
        height={81.785}
        rx={7.697}
        fill="#D3D3D3"
      />
      <path
        d="M30.203 79.76a7.697 7.697 0 0 1 7.698-7.697h99.008a7.697 7.697 0 0 1 7.697 7.697v11.546H30.203V79.76Z"
        fill="#E9E9E9"
      />
      <rect
        opacity={0.5}
        x={129.224}
        y={77.836}
        width={7.691}
        height={7.697}
        rx={3.845}
        fill="#fff"
      />
      <rect
        opacity={0.5}
        x={114.804}
        y={77.836}
        width={7.691}
        height={7.697}
        rx={3.845}
        fill="#fff"
      />
      <rect
        opacity={0.5}
        x={100.384}
        y={77.836}
        width={7.691}
        height={7.697}
        rx={3.845}
        fill="#fff"
      />
      <path
        d="M112.441 116.261c-.369-9.544-8.281-17.17-17.808-17.17-3.314.001-6.563.926-9.382 2.672a17.885 17.885 0 0 0-8.465 15.15 1.662 1.662 0 0 1-1.506 1.68 1.626 1.626 0 0 1-1.603-.976 1.625 1.625 0 0 1-.134-.649 21.103 21.103 0 0 1 1.082-6.688.812.812 0 0 0-.962-1.052 12.985 12.985 0 0 0-7.088 4.61 13.037 13.037 0 0 0-2.76 8.005c0 7.148 6.03 13.001 13.174 13.001H94.62c2.4-.003 4.776-.491 6.984-1.435a17.861 17.861 0 0 0 9.683-10.096 17.935 17.935 0 0 0 1.153-7.052Zm-11.809 8.357-6.486 6.5a1.627 1.627 0 0 1-1.147.477 1.614 1.614 0 0 1-1.147-.477l-6.485-6.5a1.63 1.63 0 0 1 0-2.3 1.62 1.62 0 0 1 2.294 0l3.717 3.728v-12.329a1.627 1.627 0 0 1 1.621-1.625 1.623 1.623 0 0 1 1.622 1.625v12.329l3.717-3.728a1.62 1.62 0 0 1 2.294 0 1.63 1.63 0 0 1 0 2.3Z"
        fill="#818CF8"
      />
      <g clipPath="url(#g)">
        <rect
          x={335.255}
          y={156.947}
          width={114.403}
          height={81.785}
          rx={7.701}
          fill="#D6D6D6"
        />
        <rect
          x={335.255}
          y={156.947}
          width={114.403}
          height={81.785}
          rx={7.697}
          fill="#D3D3D3"
        />
        <path
          d="M335.255 164.645a7.698 7.698 0 0 1 7.698-7.698h99.008a7.698 7.698 0 0 1 7.697 7.698v11.546H335.255v-11.546Z"
          fill="#E9E9E9"
        />
        <rect
          opacity={0.5}
          x={434.384}
          y={162.835}
          width={7.691}
          height={7.697}
          rx={3.845}
          fill="#fff"
        />
        <rect
          opacity={0.5}
          x={419.384}
          y={162.835}
          width={7.691}
          height={7.697}
          rx={3.845}
          fill="#fff"
        />
        <rect
          opacity={0.5}
          x={405.384}
          y={162.835}
          width={7.691}
          height={7.697}
          rx={3.845}
          fill="#fff"
        />
        <path fill="#818CF8" d="M342.946 183.888h17.305v3.849h-17.305z" />
        <path fill="#DEE6F0" d="M342.946 191.586h17.305v3.849h-17.305z" />
        <path
          fill="#818CF8"
          d="M342.946 203.132h17.305v3.849h-17.305zM425.624 183.888h17.305v3.849h-17.305z"
        />
        <path fill="#DEE6F0" d="M425.624 218.526h17.305v3.849h-17.305z" />
        <path
          fill="#E9E9E9"
          d="M366.019 183.888h56.721v3.849h-56.721zM366.019 218.526h56.721v3.849h-56.721z"
        />
        <path fill="#818CF8" d="M366.019 191.586h29.802v3.849h-29.802z" />
        <path fill="#E9E9E9" d="M366.019 203.132h36.532v3.849h-36.532z" />
        <path fill="#DEE6F0" d="M366.019 210.829h23.073v3.849h-23.073z" />
        <path fill="#818CF8" d="M366.019 226.224h23.073v3.849h-23.073z" />
        <path fill="#DEE6F0" d="M399.668 191.586h29.802v3.849h-29.802z" />
        <path
          fill="#818CF8"
          d="M404.474 203.132h37.493v3.849h-37.493zM392.938 210.829h23.073v3.849h-23.073z"
        />
        <path fill="#DEE6F0" d="M392.938 226.224h23.073v3.849h-23.073z" />
        <path fill="#E9E9E9" d="M419.856 210.829h23.073v3.849h-23.073z" />
      </g>
      <rect
        x={334.371}
        y={156.063}
        width={116.172}
        height={83.553}
        rx={8.586}
        stroke="#F9FAFB"
        strokeWidth={1.768}
      />
      <defs>
        <linearGradient
          id="a"
          x1={447.231}
          y1={18.345}
          x2={374.445}
          y2={262.419}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBEBEB" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="e"
          x1={220.189}
          y1={64.786}
          x2={220.555}
          y2={82.914}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9FAFB" />
          <stop offset={1} stopColor="#A8A8A8" />
        </linearGradient>
        <linearGradient
          id="f"
          x1={-58.5}
          y1={171.5}
          x2={72.114}
          y2={311.894}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9FAFB" />
          <stop offset={1} stopColor="#EEE" />
        </linearGradient>
        <clipPath id="c">
          <rect
            x={170}
            y={42}
            width={100}
            height={71.488}
            rx={6.732}
            fill="#fff"
          />
        </clipPath>
        <clipPath id="g">
          <rect
            x={335.255}
            y={156.947}
            width={114.403}
            height={81.785}
            rx={7.701}
            fill="#fff"
          />
        </clipPath>
        <radialGradient
          id="b"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 -12 237.495 0 240 300)"
        >
          <stop stopColor="#F9FAFB" />
          <stop offset={1} stopColor="#EEE" />
        </radialGradient>
        <filter
          id="d"
          x={164.811}
          y={69.862}
          width={111.411}
          height={19.453}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            stdDeviation={3.095}
            result="effect1_foregroundBlur_155_6518"
          />
        </filter>
      </defs>
    </svg>
  );
};
export default SvgApiIllustration;
