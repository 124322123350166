import React, { FC, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-navi';

import { useTheme } from 'hooks/theme';
import { useOutsideClickVisibility } from 'hooks/visibility';

import { LinkProps } from 'react-navi/dist/types/Link';

import { ScMoreMenuDropdown, ScNavMenu, ScNavbarLink } from './styled';

interface NavbarLinkProps extends LinkProps {
  as?: any;
}

export const NavbarLink: FC<NavbarLinkProps> = ({ ...props }) => (
  <ScNavbarLink {...props} activeClassName="active" />
);

export const NavMenu = () => {
  const { t } = useTranslation();
  const { images, isHideBonuses } = useTheme();

  function tNavMenu(key: string) {
    return t(`common:navMenu.${key}`);
  }

  return (
    <ScNavMenu>
      <NavbarLink as={Link} href="/" exact>
        <images.exchangeIcon.component />
        <span>{tNavMenu('exchange')}</span>
      </NavbarLink>

      {!isHideBonuses && (
        <NavbarLink as={Link} href="/bonus" exact>
          <images.bonusIcon.component />
          <span>{tNavMenu('bonus')}</span>
        </NavbarLink>
      )}

      <NavbarLink as={Link} href="/profile/crypto-check">
        <images.cryptoCheckIcon.component />
        <span>{tNavMenu('cryptoCheck')}</span>
      </NavbarLink>

      <MoreMenuDropdown />
    </ScNavMenu>
  );
};

const MoreMenuDropdown = () => {
  const { t } = useTranslation('common');
  const { ref, isComponentVisible, setIsComponentVisible } =
    useOutsideClickVisibility(false);

  const toggle = useCallback(
    () => void setIsComponentVisible((i) => !i),
    [setIsComponentVisible]
  );

  const { images, isHideReserves, isHidePartners } = useTheme();

  return (
    <ScMoreMenuDropdown
      ref={ref}
      className={isComponentVisible ? 'active' : ''}
    >
      <div className="dropdown-btn" onClick={toggle}>
        <images.moreMenuIcon.component />
      </div>

      {isComponentVisible && (
        <div className="dropdown-menu">
          {!isHideReserves && (
            <Link
              href="/reserve"
              onClick={toggle}
              className="dropdown-menu__link"
            >
              <images.courseIcon.component />

              <div>
                <p>{t(`navMenu.reserve`)}</p>
                <span>{t(`navMenu.reserveDesc`)}</span>
              </div>
            </Link>
          )}

          {!isHidePartners && (
            <Link
              href="/partner"
              onClick={toggle}
              className="dropdown-menu__link"
            >
              <images.heartIcon.component />

              <div>
                <p>{t(`navMenu.partner`)}</p>
                <span>{t(`navMenu.partnerDesc`)}</span>
              </div>
            </Link>
          )}

          <Link href="/faq" onClick={toggle} className="dropdown-menu__link">
            <images.faqIcon.component />

            <div>
              <div>
                <p>{t(`navMenu.faq`)}</p>
                <span>{t(`navMenu.faqDesc`)}</span>
              </div>
            </div>
          </Link>

          <hr />

          <Link
            href="/terms-of-use"
            onClick={toggle}
            className="dropdown-menu__link addons"
          >
            {t(`navMenu.termsOfUse`)}
          </Link>

          <Link
            href="/privacy"
            onClick={toggle}
            className="dropdown-menu__link addons"
          >
            {t(`navMenu.privacy`)}
          </Link>

          <Link
            href="/policy"
            onClick={toggle}
            className="dropdown-menu__link addons"
          >
            {t(`navMenu.policy`)}
          </Link>

          <Link
            href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
            className="dropdown-menu__mail"
          >
            {process.env.REACT_APP_SUPPORT_EMAIL}
          </Link>
        </div>
      )}
    </ScMoreMenuDropdown>
  );
};
