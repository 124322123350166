import * as React from 'react';
import { SVGProps } from 'react';

const SvgPhoneProfileIllustration = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={400}
      height={220}
      viewBox="0 0 400 220"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity={0.4} filter="url(#a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57 27c-7.18 0-13 5.82-13 13v101c0 7.18 5.82 13 13 13h14V62c0-7.18 5.82-13 13-13h230v-9c0-7.18-5.82-13-13-13H57Z"
          fill="#818CF8"
        />
      </g>
      <path
        opacity={0.6}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M314 49v92c0 7.18-5.82 13-13 13H71V62c0-7.18 5.82-13 13-13h230Z"
        fill="#818CF8"
      />
      <path
        opacity={0.6}
        d="M343 49H84c-7.18 0-13 5.82-13 13v118c0 7.18 5.82 13 13 13h259c7.18 0 13-5.82 13-13V62c0-7.18-5.82-13-13-13Z"
        fill="#818CF8"
      />
      <path
        opacity={0.53}
        d="M71 62c0-7.18 5.82-13 13-13h259c7.18 0 13 5.82 13 13v8H71v-8Z"
        fill="#F8FAFC"
      />
      <path
        d="M128 98c0-9.941-8.059-18-18-18s-18 8.059-18 18 8.059 18 18 18 18-8.059 18-18ZM279.5 86h-132a4.5 4.5 0 0 0 0 9h132a4.5 4.5 0 0 0 0-9Z"
        fill="#fff"
      />
      <path
        opacity={0.53}
        d="M209.5 98h-62a4.5 4.5 0 0 0 0 9h62a4.5 4.5 0 0 0 0-9ZM334.5 98h-110a4.5 4.5 0 0 0 0 9h110a4.5 4.5 0 0 0 0-9Z"
        fill="#F8FAFC"
      />
      <path
        d="M92 133a5 5 0 0 1 5-5h68a5 5 0 0 1 5 5v60H92v-60ZM175 133a5 5 0 0 1 5-5h67a5 5 0 0 1 5 5v60h-77v-60ZM257 133a5 5 0 0 1 5-5h68a5 5 0 0 1 5 5v60h-78v-60Z"
        fill="#fff"
      />
      <path d="M88 64a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z" fill="#4F46E5" />
      <path d="M103 64a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z" fill="#F8FAFC" />
      <path d="M118 64a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z" fill="#F59E0B" />
      <defs>
        <filter
          id="a"
          x={26}
          y={16}
          width={306}
          height={163}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={7} />
          <feGaussianBlur stdDeviation={9} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.537014 0 0 0 0 0.703924 0 0 0 0 0.870833 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_0_3" />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_3"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
export default SvgPhoneProfileIllustration;
