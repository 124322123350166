import * as React from 'react';
import { SVGProps } from 'react';

const SvgReferralIllustration = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={486}
      height={268}
      viewBox="0 0 486 268"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m194.897 232.178 48.569 27.994 48.569-27.994-48.569-27.993-48.569 27.993ZM146.328 204.185l48.569 27.993 48.569-27.993-48.569-27.994-48.569 27.994ZM243.466 204.185l48.569 27.993 48.569-27.993-48.569-27.994-48.569 27.994ZM146.328 148.035l48.569 28.156 48.569-28.156-48.569-27.993-48.569 27.993ZM243.466 148.035l48.569 28.156 48.569-28.156-48.569-27.993-48.569 27.993Z"
        fill="#F9FAFB"
      />
      <path
        d="m292.035 176.191 48.569 27.994 48.568-27.994-48.568-28.155-48.569 28.155ZM97.759 176.191l48.569 27.994 48.569-27.994-48.569-28.155-48.57 28.155Z"
        fill="#F9FAFB"
      />
      <path
        d="M159.207 171.966V75.282L243 26.986v96.684l-83.793 48.296Z"
        fill="#D1D5DB"
      />
      <path
        d="M326.793 171.966V75.282L243 26.986v96.684l83.793 48.296Z"
        fill="#9CA3AF"
      />
      <g
        style={{
          mixBlendMode: 'hard-light',
        }}
        fill="#854D0E"
      >
        <path d="M267.833 40.944a29.472 29.472 0 0 0-4.789 21.842A29.468 29.468 0 0 0 268.971 76l-18.464 26.863a3.15 3.15 0 0 0-.126.198 13.098 13.098 0 0 0 4.694 17.926 13.115 13.115 0 0 0 17.936-4.692l.028-.053c.029-.051.058-.104.082-.158l14.38-29.247c5.45.858 11.032.171 16.112-1.98a29.515 29.515 0 0 0 15.165-14.55l-17.334-9.99-1.32 2.058-11.029.49-4.971-9.847 1.094-2.053-17.385-10.02Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M271.963 35.868a29.496 29.496 0 0 1 12.693-6.983c2.35-.616 4.766-.94 7.195-.968a3.278 3.278 0 0 1 3.31 3.184 3.28 3.28 0 0 1-.385 1.633l-9.678 18.161-17.265-9.95a29.472 29.472 0 0 0-5.23 16.612 29.469 29.469 0 0 1 9.36-21.69Zm-3.1 39.995a29.46 29.46 0 0 1-6.26-18.306 29.469 29.469 0 0 0 6.368 18.149l-.108.157Zm-20.276 33.669a13.09 13.09 0 0 1 1.794-6.766c.038-.064.083-.135.126-.198l18.356-26.705.108.137-18.464 26.863a3.15 3.15 0 0 0-.126.198 13.096 13.096 0 0 0-1.794 6.471Zm70.08-39.288.111.064a29.515 29.515 0 0 1-15.165 14.549 29.536 29.536 0 0 1-16.112 1.98l-14.38 29.247a1.816 1.816 0 0 1-.082.158l-.028.053a13.111 13.111 0 0 1-24.424-6.763 13.107 13.107 0 0 0 16.428 12.528c3.364-.88 6.24-3.06 7.996-6.059l.028-.053c.029-.052.058-.104.082-.158l14.38-29.247c5.45.857 11.032.171 16.112-1.981a29.515 29.515 0 0 0 15.054-14.318Zm0 0-17.223-9.927-1.32 2.058-11.029.49-4.971-9.847.076-.144 4.895 9.696 11.029-.49 12.222-19.054a3.27 3.27 0 0 1 2.871-1.51 3.274 3.274 0 0 1 2.764 1.699 29.494 29.494 0 0 1 .686 27.029ZM284.2 52.874l-.076-.15.974-1.828.12.069-1.018 1.91Z"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M235.171 52.742a2.858 2.858 0 0 0-.676-1.97 38.318 38.318 0 0 0-7.514-6.785 2.854 2.854 0 0 0-2.533-.349l-8.041 2.774a28.6 28.6 0 0 0-1.424-.507l-4.536-7.226a2.859 2.859 0 0 0-2.186-1.329 38.252 38.252 0 0 0-10.111.515 2.846 2.846 0 0 0-2.047 1.554l-3.718 7.616-1.391.66-8.278-1.945a2.85 2.85 0 0 0-2.499.602 38.347 38.347 0 0 0-6.789 7.51 2.845 2.845 0 0 0-.349 2.532l2.781 8.05c-.183.478-.352.953-.507 1.424l-7.24 4.51a2.858 2.858 0 0 0-1.33 2.185 38.524 38.524 0 0 0-.128 3.257 38.311 38.311 0 0 0 .64 7.1 2.841 2.841 0 0 0 1.559 2.045l7.619 3.716.66 1.39-1.946 8.273a2.848 2.848 0 0 0 .603 2.498 38.321 38.321 0 0 0 7.514 6.786 2.864 2.864 0 0 0 2.533.349l8.041-2.774c.479.183.953.352 1.424.507l4.526 7.23a2.847 2.847 0 0 0 2.186 1.329c3.379.277 6.779.105 10.113-.511a2.855 2.855 0 0 0 2.046-1.558l3.718-7.615c.469-.207.933-.427 1.39-.66l8.282 1.954a2.853 2.853 0 0 0 2.5-.602 38.176 38.176 0 0 0 6.789-7.51 2.845 2.845 0 0 0 .349-2.532l-2.775-8.037c.184-.476.353-.95.507-1.423l6.257-3.924v-.25l.972-.61a2.848 2.848 0 0 0 1.33-2.184 38.313 38.313 0 0 0-.512-10.108 2.852 2.852 0 0 0-1.558-2.044l-7.62-3.716c-.195-.443-.401-.88-.619-1.311l1.915-8.108c.06-.255.084-.517.073-.778Zm-10.564-8.903a2.752 2.752 0 0 0-.159.05l-8.041 2.772m-1.247-.448a30.5 30.5 0 0 0-.177-.059l-.448-.712.448.712.177.059Zm-3.909 42.594a14.264 14.264 0 0 1-15.673-2.302 14.248 14.248 0 0 1-3.87-15.353 14.259 14.259 0 0 1 26.309-1.328 14.243 14.243 0 0 1-6.766 18.983Z"
        fill="#4F46E5"
        style={{
          mixBlendMode: 'hard-light',
        }}
      />
      <path
        d="M326.793 75.376v96.685L243 220.357v-96.685l83.793-48.296Z"
        fill="#D1D5DB"
      />
      <path
        d="m326.793 75.376 20.017 30.335-83.793 48.296L243 123.672l83.793-48.296ZM159.207 75.376v96.685L243 220.357v-96.685l-83.793-48.296Z"
        fill="#E5E7EB"
      />
      <path
        d="m159.207 75.376-20.017 25.682 83.793 48.296L243 123.672l-83.793-48.296Z"
        fill="#F3F4F6"
      />
    </svg>
  );
};
export default SvgReferralIllustration;
