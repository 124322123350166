import * as React from 'react';
import { SVGProps } from 'react';

const SvgStarDollarIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={28}
      height={29}
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m24.83 10.155-6.942-1.009-3.103-6.292a.879.879 0 0 0-1.57 0l-3.103 6.292-6.943 1.01a.873.873 0 0 0-.484 1.492l5.023 4.897-1.186 6.916a.873.873 0 0 0 1.268.921L14 21.117l6.21 3.265a.874.874 0 0 0 1.269-.921l-1.187-6.916 5.023-4.897a.874.874 0 0 0 .254-.5.872.872 0 0 0-.738-.993Z"
        fill="#6366F1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.624 14.499a1.72 1.72 0 0 0-.491-.562 3.254 3.254 0 0 0-.746-.407 12.193 12.193 0 0 0-.945-.329 11.752 11.752 0 0 1-.466-.15 1.962 1.962 0 0 1-.336-.147.664.664 0 0 1-.203-.17.356.356 0 0 1-.067-.217c0-.083.02-.154.062-.212a.458.458 0 0 1 .17-.141.887.887 0 0 1 .246-.079c.093-.016.193-.024.3-.024.102 0 .204.014.304.042a1.828 1.828 0 0 1 .538.253c.08.054.15.106.216.157l.043.036c.045.035.086.065.123.089a.222.222 0 0 0 .124.036c.054 0 .109-.026.164-.076l.739-.73a.714.714 0 0 0 .094-.111.195.195 0 0 0 .032-.107c0-.039-.022-.086-.065-.14a1.316 1.316 0 0 0-.177-.171 2.705 2.705 0 0 0-.258-.186 5.693 5.693 0 0 0-.312-.183 3.23 3.23 0 0 0-.795-.293c-.082-.019-.166-.029-.249-.042v-.605a.166.166 0 0 0-.08-.143.277.277 0 0 0-.152-.053h-.908c-.071 0-.124.019-.16.058a.196.196 0 0 0-.053.138v.628c-.1.018-.205.033-.3.059a2.547 2.547 0 0 0-.81.374c-.222.16-.395.354-.515.58a1.579 1.579 0 0 0-.182.757c0 .26.049.496.145.71.097.216.241.412.435.589.194.178.435.339.724.483.29.145.63.28 1.015.405.087.027.19.06.31.1.119.04.233.09.343.146.11.057.202.121.278.194a.337.337 0 0 1 .113.249.69.69 0 0 1-.04.237.484.484 0 0 1-.139.197.71.71 0 0 1-.263.136 1.41 1.41 0 0 1-.413.052c-.177 0-.347-.029-.51-.085a2.401 2.401 0 0 1-.485-.237 2.874 2.874 0 0 1-.293-.208 8.339 8.339 0 0 0-.21-.168.898.898 0 0 0-.116-.08.192.192 0 0 0-.092-.028c-.042 0-.096.023-.165.068l-.835.738c-.074.062-.111.119-.111.17 0 .047.037.107.111.179l.02.017.019.018.014.014c.155.157.333.305.534.443.201.137.416.257.643.36.227.102.464.183.71.24.021.006.044.008.065.013v.657c0 .13.071.197.213.197h.908c.155 0 .232-.066.232-.197v-.644c.145-.024.289-.051.425-.092a2.87 2.87 0 0 0 .892-.436c.253-.188.451-.415.597-.682.144-.267.217-.565.217-.892 0-.31-.059-.575-.176-.792Z"
        fill="#F9FAFB"
      />
    </svg>
  );
};
export default SvgStarDollarIcon;
