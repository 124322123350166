import * as React from 'react';
import { SVGProps } from 'react';

const SvgInfoIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16Z"
          fill="#4A90E2"
        />
        <path
          d="M15.702 10.607c.774 0 1.41-.588 1.41-1.303 0-.716-.636-1.304-1.41-1.304-.783 0-1.412.581-1.412 1.304 0 .722.63 1.303 1.412 1.303Zm2.242 11.442h-.887v-9.311c0-.537-.476-.976-1.057-.976h-1.944c-.58 0-1.056.432-1.056.976 0 .543.476.975 1.056.975h.888v8.336h-.888c-.58 0-1.056.439-1.056.975 0 .537.476.976 1.056.976h3.888c.58 0 1.056-.44 1.056-.976s-.476-.975-1.056-.975Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgInfoIcon;
