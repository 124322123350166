import * as React from 'react';
import { SVGProps } from 'react';

const SvgCheckIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={94}
      height={94}
      viewBox="0 0 94 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="M47 94c25.957 0 47-21.043 47-47S72.957 0 47 0 0 21.043 0 47s21.043 47 47 47Z"
          fill="#22C55E"
        />
        <path
          d="M47 89c23.196 0 42-18.804 42-42S70.196 5 47 5 5 23.804 5 47s18.804 42 42 42Z"
          fill="#4ADE80"
        />
        <path
          d="m41.022 59.1-10.51-10.364L26 53.186 41.022 68 72 37.45 67.487 33 41.022 59.1Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h94v94H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgCheckIcon;
