import * as React from 'react';
import { SVGProps } from 'react';

const SvgMoreMenuIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="#6B7280"
        d="M2 2h4v4H2zM10 2h4v4h-4zM18 2h4v4h-4zM2 10h4v4H2zM10 10h4v4h-4zM18 10h4v4h-4zM2 18h4v4H2zM10 18h4v4h-4zM18 18h4v4h-4z"
      />
    </svg>
  );
};
export default SvgMoreMenuIcon;
