import * as React from 'react';
import { SVGProps } from 'react';

const SvgCryptoImage = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={351}
      height={260}
      viewBox="0 0 351 260"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="m99.048 178.75 147.43 110.268h-294.86L99.047 178.75Z"
          fill="#F1F5F9"
        />
        <path
          d="m257.679 191.131 147.43 110.268H110.248l147.431-110.268Z"
          fill="#E2E8F0"
        />
        <path
          d="m175.655 171.012 147.43 110.268H28.225l147.43-110.268Z"
          fill="#CBD5E1"
        />
        <path
          d="m175.655 171.012 147.43 110.268H28.225l147.43-110.268Z"
          fill="url(#b)"
        />
      </g>
      <path
        d="M179.814 55.714V160.82h-8.447c-22.156 0-40.118-23.53-40.118-52.552 0-29.023 17.962-52.553 40.118-52.553h8.447Z"
        fill="#EAB308"
      />
      <path
        d="M134.11 126.565a62.285 62.285 0 0 0 1.202 3.754h44.865v-3.754H134.11ZM138.087 136.89a54.897 54.897 0 0 0 2.049 3.754h40.041v-3.754h-42.09ZM132.066 116.243c.147 1.269.341 2.517.557 3.754h47.554v-3.754h-48.111ZM144.79 147.212a44.637 44.637 0 0 0 3.563 3.754h31.824v-3.754H144.79ZM180.177 158.942v-3.754h-26.498a34.703 34.703 0 0 0 7.437 3.754h19.061ZM132.787 95.6a65.605 65.605 0 0 0-.594 3.75h47.984V95.6h-47.39ZM131.651 105.921a75.892 75.892 0 0 0-.044 2.346c0 .471.017.937.027 1.408h48.543v-3.754h-48.526ZM164.195 56.652a33.482 33.482 0 0 0-9.044 3.754h25.026v-3.754h-15.982ZM149.366 64.63a43.373 43.373 0 0 0-3.749 3.755h34.56V64.63h-30.811ZM140.7 74.953a54.223 54.223 0 0 0-2.145 3.754h41.622v-3.754H140.7ZM135.646 85.275a61.489 61.489 0 0 0-1.252 3.754h45.783v-3.754h-44.531Z"
        fill="#FEF9C3"
      />
      <path
        d="M180.047 160.819c22.157 0 40.118-23.529 40.118-52.552 0-29.024-17.961-52.553-40.118-52.553-22.156 0-40.118 23.529-40.118 52.553 0 29.023 17.962 52.552 40.118 52.552Z"
        fill="#EAB308"
      />
      <path
        d="M180.047 151.903c17.234 0 31.204-19.537 31.204-43.636 0-24.1-13.97-43.636-31.204-43.636-17.233 0-31.204 19.536-31.204 43.636 0 24.099 13.971 43.636 31.204 43.636Z"
        fill="#FEF9C3"
      />
      <path
        d="M196.219 116.617c.145-4.675-1.994-7.769-5.235-9.107 2.405-1.418 4.07-4.09 4.18-7.787.14-4.594-2.317-7.932-7.129-9.173l.177-5.673-5.239-.167-.171 5.274-2.975-.094.171-5.274-5.234-.166-.171 5.273-4.771-.147-3.323-.103-.167 5.24 3.323.102-.773 25.484-3.671-.118-.167 5.239 3.671.113 4.771.147-.186 6.132 5.234.167.191-6.132 2.975.093-.191 6.132 5.239.167.192-6.334c5.934-.64 9.111-3.963 9.279-9.288Zm-19.914-21.585 7.091.217c3.933.121 6.113 1.834 6.013 5.173-.1 3.339-2.381 4.858-6.322 4.756l-8.068-.252.301-9.91.985.016Zm7.438 25.723-8.572-.266-.606-.017.321-10.373 9.188.284c4.281.13 6.553 1.794 6.441 5.385-.112 3.59-2.47 5.12-6.772 4.987Z"
        fill="#EAB308"
      />
      <defs>
        <linearGradient
          id="b"
          x1={175.655}
          y1={171.012}
          x2={175.655}
          y2={230.595}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0.57} />
          <stop offset={1} stopColor="#CBD5E1" />
        </linearGradient>
        <clipPath id="a">
          <path
            fill="#fff"
            transform="translate(0 30.952)"
            d="M0 0h350.536v198.095H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgCryptoImage;
