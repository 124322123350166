import * as React from 'react';
import { SVGProps } from 'react';

const SvgQrIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 17.5v-1h-3v-3h3v2h2v2h-1v2h-2v2h-2v-3h2v-1h1Zm5 4h-4v-2h2v-2h2v4ZM3 3.5h8v8H3v-8Zm2 2v4h4v-4H5Zm8-2h8v8h-8v-8Zm2 2v4h4v-4h-4Zm-12 8h8v8H3v-8Zm2 2v4h4v-4H5Zm13-2h3v2h-3v-2Zm-12-7h2v2H6v-2Zm0 10h2v2H6v-2Zm10-10h2v2h-2v-2Z"
        fill="#1F2937"
      />
    </svg>
  );
};
export default SvgQrIcon;
