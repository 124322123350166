import * as React from 'react';
import { SVGProps } from 'react';

const SvgContactsIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={24}
      >
        <path d="M24 0H0v24h24V0Z" fill="#fff" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M20 22H6a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h14a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1Zm-1-2v-2H6a1 1 0 0 0 0 2h13ZM5 16.17c.313-.11.65-.17 1-.17h13V4H6a1 1 0 0 0-1 1v11.17ZM12 10a2 2 0 1 1 0-4 2 2 0 0 1 0 4Zm-3 4a3 3 0 0 1 6 0H9Z"
          fill="#1F2937"
        />
      </g>
    </svg>
  );
};
export default SvgContactsIcon;
