import * as React from 'react';
import { SVGProps } from 'react';

const SvgPlatinumStatus = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={68}
      height={68}
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={68}
        height={68}
      >
        <path d="M68 0H0v68h68V0Z" fill="#fff" />
      </mask>
      <g mask="url(#a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.744 20.776c0 4.946-2.494 9.293-6.255 11.773-.6.395-1.103.34-1.608 0-3.733-2.535-6.254-6.84-6.254-11.773 0-4.947 2.575-9.157 6.254-11.773.56-.396.94-.464 1.608 0 3.72 2.561 6.255 6.826 6.255 11.773ZM47.533 20.776c0 4.946 2.494 9.293 6.255 11.773.6.395 1.117.34 1.621 0 3.734-2.535 6.255-6.84 6.255-11.773 0-4.947-2.576-9.157-6.255-11.773-.558-.396-.94-.464-1.621 0-3.72 2.561-6.255 6.826-6.255 11.773Z"
          fill="#78C2A4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.003 30.954c-2.467 4.28-2.494 9.294-.477 13.327.313.64.79.859 1.39.804 4.497-.327 8.83-2.793 11.31-7.072 2.466-4.279 2.343-9.225.477-13.327-.287-.626-.586-.872-1.39-.803-4.497.367-8.844 2.793-11.31 7.072Zm-35.729 0c2.467 4.28 2.494 9.294.477 13.327-.327.64-.79.859-1.39.804-4.51-.327-8.843-2.793-11.31-7.072-2.466-4.279-2.343-9.225-.463-13.327.286-.626.586-.872 1.39-.803 4.497.367 8.83 2.793 11.296 7.072Z"
          fill="#95D6A4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.177 36.432a13.835 13.835 0 0 0 1.362 7.85c.314.64.79.858 1.39.803 4.497-.327 8.83-2.793 11.31-7.072 1.594-2.752 2.112-5.764 1.772-8.666a13.813 13.813 0 0 1-1.772 5.355c-2.466 4.279-6.8 6.745-11.31 7.072-.613.04-1.076-.164-1.39-.804a13.561 13.561 0 0 1-1.362-4.538Zm-32.063 0a13.836 13.836 0 0 1-1.363 7.85c-.327.64-.79.858-1.39.803-4.51-.327-8.843-2.793-11.31-7.072-1.58-2.752-2.098-5.764-1.771-8.666a13.81 13.81 0 0 0 1.771 5.355c2.467 4.279 6.8 6.745 11.31 7.072.6.04 1.077-.164 1.39-.804a13.884 13.884 0 0 0 1.363-4.538Z"
          fill="#78C2A4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.856 39.485c-4.279 2.466-6.814 6.8-7.072 11.31-.041.708.258 1.13.804 1.403 4.06 1.962 9.047 2.003 13.326-.477 4.279-2.466 6.65-6.813 7.072-11.31.069-.695-.068-1.049-.804-1.403-4.074-1.935-9.048-2.003-13.326.477Zm-39.435 0c4.279 2.466 6.8 6.8 7.072 11.31.04.708-.259 1.13-.804 1.403-4.06 1.949-9.048 1.99-13.327-.49C3.084 49.24.726 44.894.29 40.398c-.068-.695.068-1.05.804-1.404 4.075-1.921 9.048-1.99 13.327.49Z"
          fill="#B3E59F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.11 48.533a15.413 15.413 0 0 0-.326 2.248c-.041.709.259 1.131.804 1.404 4.06 1.962 9.048 2.003 13.326-.477 4.279-2.467 6.65-6.813 7.072-11.31.055-.518-.013-.845-.368-1.131-.885 3.679-3.12 7.058-6.704 9.13-4.278 2.466-9.266 2.439-13.326.477a1.585 1.585 0 0 1-.477-.341Zm-25.93 0c.163.736.272 1.485.327 2.248.04.709-.26 1.131-.804 1.404-4.075 1.962-9.062 2.003-13.34-.477C3.083 49.24.726 44.895.29 40.398c-.054-.518.014-.845.368-1.131.886 3.679 3.12 7.058 6.705 9.13 4.278 2.466 9.265 2.439 13.326.463.19-.082.355-.191.49-.327Z"
          fill="#95D6A4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.735 47.933c-4.946 0-9.293 2.493-11.773 6.254-.395.6-.34 1.104 0 1.608 2.535 3.734 6.84 6.255 11.773 6.255 4.947 0 9.157-2.576 11.774-6.255.395-.558.463-.94 0-1.608-2.562-3.706-6.84-6.254-11.774-6.254Zm-33.194 0c4.947 0 9.294 2.493 11.774 6.254.395.6.34 1.104 0 1.608-2.535 3.734-6.827 6.255-11.774 6.255-4.946 0-9.157-2.576-11.773-6.255-.395-.558-.463-.94 0-1.608 2.562-3.706 6.84-6.254 11.773-6.254Z"
          fill="#CCF49F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.562 53.33a9.785 9.785 0 0 0-.613.858c-.396.6-.341 1.103 0 1.608 2.534 3.733 6.84 6.254 11.773 6.254 4.946 0 9.157-2.575 11.773-6.254.395-.56.463-.94 0-1.608-.204-.3-.409-.586-.64-.859-2.644 3.23-6.582 5.423-11.147 5.423S42.151 56.6 39.562 53.33Zm-10.847 0c.218.272.422.558.613.858.396.6.34 1.103 0 1.608-2.534 3.733-6.826 6.254-11.773 6.254-4.946 0-9.157-2.575-11.773-6.254-.395-.56-.463-.94 0-1.608.204-.3.422-.586.64-.859 2.644 3.23 6.582 5.423 11.133 5.423 4.565 0 8.571-2.153 11.16-5.423Z"
          fill="#B3E59F"
        />
        <mask
          id="b"
          style={{
            maskType: 'luminance',
          }}
          maskUnits="userSpaceOnUse"
          x={2}
          y={3}
          width={65}
          height={62}
        >
          <path d="M66.3 3.4H2.55v61.2H66.3V3.4Z" fill="#fff" />
        </mask>
        <g mask="url(#b)" fillRule="evenodd" clipRule="evenodd">
          <path
            d="M28.42 3.4c-.729-.014-1.247.378-1.584 1.05-3.601 7.07-8.997 11.45-16.9 12.949-.771.14-1.206.587-1.22 1.385-.154 5.893.56 32.252 24.707 45.564.63.35 1.345.35 1.976 0 24.16-13.312 24.875-39.67 24.72-45.564-.027-.798-.448-1.245-1.218-1.385C50.997 15.9 45.6 11.519 42 4.45c-.337-.658-.855-1.064-1.584-1.05H28.42Z"
            fill="#454B5D"
          />
          <path
            d="M8.744 18.533c-.014.084-.014.154-.028.252-.154 5.893.56 32.252 24.707 45.564.63.35 1.345.35 1.976 0 24.16-13.312 24.875-39.67 24.72-45.564 0-.084-.013-.168-.027-.252C59.77 27.59 56.7 49.47 35.399 61.2c-.63.35-1.345.35-1.976 0C12.136 49.47 9.067 27.59 8.744 18.533Z"
            fill="#1E2435"
          />
          <path
            d="M16.214 23.334c.336 4.438 1.289 8.987 2.719 13.005 2.886 8.175 7.805 14.81 14.826 19.583.449.294.897.28 1.346 0 7.02-4.773 11.94-11.394 14.827-19.583 1.415-4.018 2.382-8.567 2.718-13.005-6.054-3.331-12.108-4.983-18.218-4.955-6.04.014-12.136 1.666-18.218 4.955Z"
            fill="#8FDEFF"
          />
          <path
            d="M16.214 23.334c.336 4.437 1.289 8.987 2.719 13.004 2.886 8.175 7.805 14.81 14.826 19.584.449.294.897.28 1.346 0 7.02-4.774 11.94-11.395 14.827-19.584 1.415-4.017 2.382-8.567 2.718-13.004a6.131 6.131 0 0 1-.308-.168c-.477 3.457-1.29 6.9-2.396 10.022-2.887 8.175-7.806 14.81-14.827 19.584-.449.28-.897.294-1.346 0-7.02-4.773-11.94-11.395-14.827-19.584-1.107-3.121-1.934-6.565-2.396-10.022a3.755 3.755 0 0 0-.336.168Z"
            fill="#4490B0"
          />
          <path
            d="M34.418 46.193c-10.034 0-18.863.966-27.426 2.716-.434.084-.77-.07-.756-.63l.112-5.151a2.06 2.06 0 0 0-.449-1.358l-3.209-4.2c-.294-.392-.112-.727.294-.825 9.824-2.352 19.998-3.668 31.434-3.668 11.45 0 21.61 1.316 31.434 3.681.406.098.588.434.294.826l-3.21 4.2c-.322.42-.448.868-.448 1.358l.112 5.151c.014.56-.322.714-.757.63-8.548-1.75-17.377-2.73-27.425-2.73Z"
            fill="#FFD04E"
          />
          <path
            d="M34.418 46.193c-10.034 0-18.863.966-27.425 2.716-.435.084-.771-.07-.757-.63l.056-2.772c.112.266.378.337.7.266 8.563-1.75 17.392-2.715 27.426-2.715 10.048 0 18.863.966 27.426 2.715.322.07.588 0 .7-.266l.056 2.772c.014.56-.322.714-.756.63-8.549-1.736-17.378-2.716-27.426-2.716Z"
            fill="#AA8215"
          />
          <path
            d="m50.94 18.925 1.584 2.94c.098.195.266.307.49.35l3.294.587c.224.042.252.14.098.308l-2.312 2.422a.62.62 0 0 0-.183.574l.449 3.317c.028.21-.056.28-.252.182l-3.013-1.456a.654.654 0 0 0-.603 0l-3.013 1.456c-.21.098-.28.028-.252-.182l.448-3.317a.684.684 0 0 0-.182-.574l-2.34-2.408c-.14-.154-.14-.252.098-.308l3.293-.588a.681.681 0 0 0 .49-.35l1.584-2.94c.098-.181.224-.181.322-.013Zm-33.03 0-1.584 2.94c-.098.195-.267.307-.49.35l-3.294.587c-.224.042-.252.14-.098.308l2.298 2.436a.62.62 0 0 1 .182.574l-.434 3.317c-.028.21.056.28.252.182l3.013-1.455a.654.654 0 0 1 .603 0l3.027 1.455c.21.098.28.028.252-.182l-.448-3.317c-.042-.238.042-.42.182-.574l2.312-2.422c.14-.154.14-.252-.098-.308l-3.293-.588a.71.71 0 0 1-.49-.35l-1.584-2.94c-.084-.181-.21-.181-.309-.013ZM34.123 8.776 31.25 14.11c-.183.35-.477.56-.883.644l-5.956 1.078c-.393.07-.449.266-.182.546l4.19 4.382c.28.293.392.63.336 1.035l-.813 6.006c-.056.378.112.504.463.336l5.465-2.632a1.249 1.249 0 0 1 1.093 0l5.466 2.632c.378.181.518.056.462-.336l-.813-6.006c-.056-.434.07-.756.337-1.035l4.176-4.382c.266-.28.266-.462-.182-.546l-5.956-1.078c-.407-.07-.7-.294-.883-.644l-2.873-5.333c-.154-.308-.392-.322-.574 0Z"
            fill="#FFD04E"
          />
          <path
            d="m53.94 26.4.406 3.024c.028.21-.056.28-.252.182L51.08 28.15a.654.654 0 0 0-.603 0l-3.013 1.456c-.21.098-.28.028-.252-.182l.406-3.024 2.86-1.372a.654.654 0 0 1 .602 0L53.94 26.4Zm-39.043 0-.407 3.038c-.028.21.056.28.252.182l3.013-1.456a.654.654 0 0 1 .603 0l3.027 1.456c.21.098.28.028.252-.182l-.406-3.024-2.873-1.372a.654.654 0 0 0-.603 0L14.897 26.4Zm13.425-1.386-.378 2.772c-.056.378.112.504.462.336l5.466-2.632a1.249 1.249 0 0 1 1.093 0l5.465 2.632c.379.182.519.056.463-.336l-.379-2.758-.084-.042-5.465-2.631a1.248 1.248 0 0 0-1.093 0l-5.466 2.631c-.028.014-.056.014-.084.028Z"
            fill="#AA8215"
          />
        </g>
      </g>
    </svg>
  );
};
export default SvgPlatinumStatus;
