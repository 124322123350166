import { Theme, ThemeConfig, ThemeDesignConfig } from '../contracts';

import { images } from './config-images';
import { paymentImages } from './config-pm-images';

export const themeConfig: ThemeConfig = {
  defaultTitle: 'CryptoEx',
  name: 'CryptoEx',

  // images2
  errorIcon: images.errorIcon.path,
  successIcon: images.successIcon.path,
  infoIcon: images.infoIcon.path,
  crossArrowsIcon: `url(${images.crossArrowsIcon.path}) no-repeat 50% / contain`,

  // fonts
  fontFamily: 'Roboto',
  fontFamilyCyrillic: '',
  mainFont: 'Roboto, sans-serif',

  // media
  smallMobileSize: '374px',
  mobileSize: '767px',
  tabletSize: '1024px',

  // jivo site
  jivoMobile: `url(${images.jivoMobile.path}) no-repeat 50% 50% !important`,

  // circle progress bar
  progressCircleDesktopSize: 170,
  progressCircleMobileSize: 140,
  progressCircleTrailColor: '#E2E8F0',

  isSiteAssistantAvailable: true,

  // exchange only for authorized users
  requiredAuthForExchange: true,

  images,
  paymentImages,
};

export const themeDesignConfig: ThemeDesignConfig = {
  colors: {
    button: {
      fill: {
        primaryBg: '#111827',
        primaryHover: '#4B5563',
        primaryPressed: '#374151',
        disabled: '#CBD5E1',
        secondaryBg: '#E5E7EB',
        secondaryHover: '#D1D5DB',
        secondaryPressed: '#9CA3AF',
        dangerBg: '#B91C1C',
        dangerHover: '#EF4444',
        dangerPressed: '#991B1B',
        tertiary01: '#FFFFFF',
        tertiaryHover01: '#F9FAFB',
        tertiaryPressed01: '#F3F4F6',
        tertiary02: '#000000',
        tertiaryHover02: '#6366F120',
        tertiaryPressed02: '#6366F130',
        tertiary03: '#000000',
        tertiaryHover03: '#F8717120',
        tertiaryPressed03: '#F8717140',
        gradient: 'linear-gradient(180deg, #FFD04E 0%, #D8A61B 100%)',
      },
      text: {
        primaryLabel: '#FFFFFF',
        secondaryLabel: '#111827',
        tertiaryLabel01: '#1F2937',
        tertiaryLabel02: '#6366F1',
        tertiaryLabel03: '#EF4444',
        linkLabel: '#1F2937',
        linkLabelHover: '#6B7280',
        linkLabelPressed: '#9CA3AF',
        textButtonDisable: '#00000020',
      },
      border: {
        secondary: '#00000010',
        border01: '#6B7280',
        border02: '#6366F1',
        border03: '#EF4444',
        borderDisable: '#00000010',
      },
      icon: {
        icon01: '#6366F1',
        iconHover01: '#818CF8',
        iconPressed01: '#4F46E5',
        icon02: '#1F2937',
        iconHover02: '#6B7280',
        iconPressed02: '#9CA3AF',
        iconDisable: '#00000020',
      },
    },
    background: {
      mainPage: '#F9FAFB',
      overlay: '#000000B3', // 70% opacity
      foreground: '#FFFFFF',
      loader: 'rgba(197, 197, 197, 0.4)',
      modal: '#FFFFFF',
      header: '#FFFFFF',
      brand: '#6366F1',
      success: '#22C55E',
      warning: '#EAB308',
      danger: '#EF4444',
      info: '#3B82F6',
      inverse: '#111827',
    },
    layer: {
      layer01: '#F9FAFB',
      layerHover01: '#F3F4F6',
      layerSelected01: '#EEF2FF',
      layerBrand: 'rgba(99, 102, 241, 0.2)',
      layer02: '#F9FAFB',
      layer03: '#E5E7EB',
      layer04: '#F9FAFB',
      success: 'rgba(74, 222, 128, 0.16)',
      warning: 'rgba(250, 204, 21, 0.16)',
      danger: 'rgba(239, 68, 68, 0.08)',
      info: 'rgba(59, 130, 246, 0.16)',
    },
    text: {
      primary: '#1F2937',
      secondary: '#4B5563',
      subtle: '#6B7280',
      placeholder: 'rgba(0, 0, 0, 0.3)',
      inverse: '#F9FAFB',
      disabledSubtle: 'rgba(0, 0, 0, 0.2)',
      disabledStrong: 'rgba(0, 0, 0, 0.3)',
      brand: '#6366F1',
      success: '#059669',
      warning: '#CA8A04',
      danger: '#EF4444',
      info: '#3B82F6',
    },
    icons: {
      primary: '#1F2937',
      secondary: '#4B5563',
      subtle: '#6B7280',
      disabledStrong: 'rgba(0, 0, 0, 0.3)',
      inverse: '#F9FAFB',
      success: '#059669',
      warning: '#CA8A04',
      danger: '#EF4444',
      info: '#3B82F6',
    },
    border: {
      system: '#D1D5DB',
      tile: '#F3F4F6',
      separator: '#00000014',
      field: '#E5E7EB',
      interactive: '#A5B4FC',
      hover: '#818CF8',
      focus: '#6366F1',
      success: '#34D399',
      warning: '#EAB308',
      danger: '#EF4444',
      info: '#3B82F6',
    },
    field: {
      default: 'rgba(117, 123, 136, 0.08)',
      hover: 'rgba(0, 0, 0, 0.16)',
      focused: 'rgba(0, 0, 0, 0.08)',
      disabled: '#E5E7EB',
    },
    bonuses: {
      bronze01: '#D97706',
      bronze02: 'rgba(217, 119, 6, 0.2)',
      silver01: '#64748B',
      silver02: 'rgba(100, 116, 139, 0.2)',
      gold01: '#CA8A04',
      gold02: 'rgba(161, 98, 7, 0.2)',
      platinum01: '#3B82F6',
      platinum02: 'rgba(59, 130, 246, 0.2)',
      diamond01: '#06B6D4',
      diamond02: 'rgba(6, 182, 212, 0.2)',
    },
    overlay: {
      '01': 'rgba(15,23,42,0.1)',
      '02': 'rgba(15,23,42,0.2)',
      '03': 'rgba(15,23,42,0.3)',
      '04': 'rgba(15,23,42,0.4)',
      '05': 'rgba(15,23,42,0.5)',
      '06': 'rgba(15,23,42,0.6)',
      '07': 'rgba(15,23,42,0.7)',
      '08': 'rgba(15,23,42,0.8)',
      '09': 'rgba(15,23,42,0.9)',
    },
  },

  spacing: {
    's-none': '0px',
    's-01': '1px',
    's-02': '2px',
    's-04': '4px',
    's-06': '6px',
    's-08': '8px',
    's-10': '10px',
    's-12': '12px',
    's-16': '16px',
    's-20': '20px',
    's-24': '24px',
    's-28': '28px',
    's-32': '32px',
    's-36': '36px',
    's-40': '40px',
    's-44': '44px',
    's-48': '48px',
    's-56': '56px',
    's-64': '64px',
    's-80': '80px',
    'btn-content': '8px',
    'btn-y': '20px',
    'btn-x': '24px',
    'form-y': '40px',
    'form-x': '32px',
    'frame-y': '36px',
    'frame-x': '36px',
    'modal-y': '40px',
    'modal-x': '36px',
  },

  radius: {
    mainStrong: '24px',
    mainSubtle: '8px',
    frame: '28px',
    modal: '24px',
    button: '10px',
    field: '12px',
    layer: '8px',
    card: '12px',
  },

  typography: {
    fontFamily: 'Manrope',
    fontFamilyCyrillic: '',
    mainFont: 'Manrope, sans-serif',
    'display-large': {
      size: '40px',
      height: '54px',
      weight: 600,
      family: 'Manrope, sans-serif',
    },
    'display-medium': {
      size: '32px',
      height: '44px',
      weight: 700,
      family: 'Manrope, sans-serif',
    },
    'heading-large': {
      size: '28px',
      height: '38px',
      weight: 700,
      family: 'Manrope, sans-serif',
    },
    'heading-medium': {
      size: '22px',
      height: '30px',
      weight: 600,
      family: 'Manrope, sans-serif',
    },
    'heading-small': {
      size: '18px',
      height: '18px',
      weight: 700,
      family: 'Manrope, sans-serif',
    },
    'label-large': {
      size: '16px',
      height: '22px',
      weight: 700,
      family: 'Manrope, sans-serif',
    },
    'label-medium': {
      size: '14px',
      height: '20px',
      weight: 700,
      family: 'Manrope, sans-serif',
    },
    'label-small': {
      size: '12px',
      height: '16px',
      weight: 700,
      family: 'Manrope, sans-serif',
    },
    'paragraph-large': {
      size: '16px',
      height: '22px',
      weight: 500,
      family: 'Manrope, sans-serif',
    },
    'paragraph-medium': {
      size: '14px',
      height: '20px',
      weight: 500,
      family: 'Manrope, sans-serif',
    },
    'paragraph-small': {
      size: '12px',
      height: '16px',
      weight: 500,
      family: 'Manrope, sans-serif',
    },
    'overline-medium': {
      size: '13px',
      height: '18px',
      weight: 400,
      family: 'Manrope, sans-serif',
    },
    'overline-small': {
      size: '11px',
      height: '14px',
      weight: 400,
      family: 'Manrope, sans-serif',
    },
    'overline-x-small': {
      size: '10px',
      height: '14px',
      weight: 400,
      family: 'Manrope, sans-serif',
    },
  },
  illustrations: {
    referral: images.referralIllustration,
    phoneProfile: images.phoneProfileIllustration,
    api: images.apiIllustration,
    emptyTable: images.emptyTableIllustration,
  },
  background: {},
};

export default { ...themeConfig, ...themeDesignConfig } as Theme;
