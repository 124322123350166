import React, { FC, useCallback } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-navi';

import { useTheme } from 'hooks/theme';
import { useOutsideClickVisibility } from 'hooks/visibility';

import { uuid } from 'helpers/base';
import { bodyScrollLockToggle } from 'helpers/common';
import { getLocaleBestchangeUrl } from 'helpers/i18n';

import { LangToggle, LangToggleType } from 'components/LangToggle';
import { Icon } from 'components/UI/Icon';

import { ENV } from 'env/env.config';

import { Languages, availableLng } from 'lib/i18n';
import { LinkProps } from 'react-navi/dist/types/Link';
import { Image } from 'themes/contracts';

import Burger from './components/Burger';
import { ScMobileBg, ScMobileMenu, ScNavLink } from './styled';

interface SidebarLinkProps extends LinkProps {
  as?: any;
}

export const NavLink: FC<SidebarLinkProps> = ({ ...props }) => (
  <ScNavLink {...props} activeClassName="active" />
);

export const MobileMenu = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { images, isHideBonuses, isHidePartners, isHideReserves } = useTheme();

  const { isComponentVisible, setIsComponentVisible } =
    useOutsideClickVisibility(undefined);

  const toggle = useCallback(() => {
    bodyScrollLockToggle();
    setIsComponentVisible((prev) => !prev);
  }, [setIsComponentVisible]);

  function tNavMenu(key: string) {
    return t(`common:navMenu.${process.env.THEME}.${key}`, {
      defaultValue: t(`common:navMenu.${key}`),
    });
  }

  const cryptoItems = [
    {
      href: '/profile/crypto-check',
      icon: images.cryptoCheckIcon,
      text: 'cryptoCheck',
      exact: true,
      as: Link,
    },
  ];

  const items: {
    href: string;
    icon: Image;
    text: string;
    exact: boolean;
    as?: any;
  }[] = [
    {
      href: '/',
      icon: images.exchangeIcon,
      text: 'exchange',
      exact: true,
      as: Link,
    },
    ...(isHideBonuses
      ? []
      : [
          {
            href: '/bonus',
            icon: images.bonusIcon,
            text: 'bonus',
            exact: true,
            as: Link,
          },
        ]),
    ...cryptoItems,
    ...(isHideReserves
      ? []
      : [
          {
            href: '/reserve',
            icon: images.courseIcon,
            text: 'reserve',
            exact: true,
            as: Link,
          },
        ]),
    ...(isHidePartners
      ? []
      : [
          {
            href: '/partner',
            icon: images.partnersIcon,
            text: 'partner',
            exact: true,
            as: Link,
          },
        ]),
    {
      href: '/faq',
      icon: images.faqIcon,
      text: 'faq',
      exact: true,
      as: Link,
    },
  ];

  return (
    <>
      <div>
        <Burger onClick={toggle} isOpen={isComponentVisible} />

        {isComponentVisible !== undefined && (
          <ScMobileMenu
            className={cx(
              isComponentVisible && 'opened',
              !isComponentVisible && 'closed'
            )}
          >
            <div>
              <ul className="menu" onClick={toggle}>
                {items.map(({ href, icon, text, exact, as }) => {
                  return (
                    <li key={uuid()}>
                      <NavLink as={as} href={href} exact={exact}>
                        {icon ? (
                          <icon.component />
                        ) : (
                          <span className="defaultIcon" />
                        )}
                        <span>{tNavMenu(text)}</span>
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="footer-links">
              <div>
                <Link
                  href="/policy"
                  onClick={() => setIsComponentVisible(false)}
                  className="nav__link"
                >
                  {t('common:navMenu.policy')}
                </Link>

                <Link
                  href="/terms-of-use"
                  onClick={() => setIsComponentVisible(false)}
                  className="nav__link"
                >
                  {t(`navMenu.termsOfUse`)}
                </Link>

                <Link
                  href="/privacy"
                  onClick={() => setIsComponentVisible(false)}
                  className="nav__link"
                >
                  {t(`navMenu.privacy`)}
                </Link>

                <Link
                  href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
                  className="dropdown-menu__mail"
                >
                  {process.env.REACT_APP_SUPPORT_EMAIL}
                </Link>

                {availableLng.length > 1 && (
                  <LangToggle type={LangToggleType.Mobile} />
                )}
              </div>
              <div>
                <span>
                  {t(`common:footer.${ENV.APP_THEME}.copyright`, {
                    value: ENV.APP_TITLE,
                    defaultValue: t('common:footer.copyright'),
                  })}
                </span>
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href={getLocaleBestchangeUrl(language as Languages)}
                >
                  <Icon width={77} name="BestchangeIcon" />
                </a>
              </div>
            </div>
          </ScMobileMenu>
        )}
      </div>

      {isComponentVisible !== undefined && (
        <ScMobileBg
          onClick={toggle}
          className={cx(
            isComponentVisible && 'opened',
            !isComponentVisible && 'closed'
          )}
        />
      )}
    </>
  );
};
